import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../pages/home")
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../pages/about")
  },
  {
    path: "/info_1",
    name: "info_1",
    component: () => import("../pages/info/info1")
  },
  {
    path: "/info_2",
    name: "info2",
    component: () => import("../pages/info/info2")
  },
  {
    path: "/info_3",
    name: "info3",
    component: () => import("../pages/info/info3")
  },
  {
    path: "/info_4",
    name: "info4",
    component: () => import("../pages/info/info4")
  },
  {
    path: "/info_5",
    name: "info5",
    component: () => import("../pages/info/info5")
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});
export default router;
