<template>
  <div class="app">
    <Nav />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Nav from "./components/nav/index";
import Footer from "./components/footer/index";

export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  created() {
    let host = window.location.host;
    if (host.indexOf("beyouvision") != -1) {
      document.title = '比如视界健康'
    } else {
      document.title = '珍视智慧'
    }
  },
};
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
.app {
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
