<template>
  <div class="footer">
    {{companyName}}
    <a target="_blank" href="https://beian.miit.gov.cn">{{ICP}}</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyName:'',
      ICP:''
    };
  },
  created() {
    let host = window.location.host;
    if (host.indexOf("beyouvision") != -1) {
     this.companyName = '北京比如视界健康科技有限公司'
     this.ICP = '京ICP备2023001166号-1'
    } else if (host.indexOf("zhenshizhihui.com") != -1) {
      this.companyName = '北京珍视智慧文化传播有限公司'
      this.ICP = '京ICP备2022034996号-1'
    } else if (host.indexOf("zhenshizhihui.cn") != -1) {
      this.companyName = '青岛珍视智慧科技有限公司'
      this.ICP = '鲁ICP备2023010089号-1'
    } else {
     this.companyName = '北京珍视智慧文化传播有限公司'
      this.ICP = '京ICP备2022023614号'
    }
  },
  methods: {
    linkTo() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  height: 93px;
  line-height: 93px;
  background: rgba(211, 218, 222, 1);
  min-width: 1200px;
  text-align: center;
  /* color: rgb(206, 203, 225); */
  color: #000;
  font-size: 18px;
  /* padding-top: 60px; */
}

a {
  font-size: 18px;
  /* color: rgb(206, 203, 225); */
  color: rgba(102, 102, 102, 1);
  text-decoration: none;
}
a:hover {
  color: #000;
}
</style>