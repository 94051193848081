<template>
  <div class="nav">
    <!-- <img src="@/assets/header.jpg" alt="" class="header" > -->
    <!-- <div class="nav_inner"> -->
    <!-- <img src="@/assets/logo.png" alt="" class="logo" />
      <div class="nav_name">
        <span>京师智慧</span>
        <span>jingshizhihui</span>
      </div> -->

    <!-- </div> -->
    <div class="nav_inner">
      <div>
       <img src="@/assets/image/home/logo.png" alt="" class="logo" @click="goHome">
      </div>
      <div>
         <img src="@/assets/image/home/icon_tel.png" alt="" class="tel">
        <p>13269331975</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push('/')
    }
  },
};
</script>

<style scoped>
.nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  /* line-height: 70px; */
  /* border: 1px solid rgba(#ffffff, 0.14); */
  background: #fff;
  z-index: 99;
  min-width: 1200px;
}
.header {
  width: 100%;
  height: 100%;
  display: block;
}
.nav_inner {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav_inner div {
  display: flex;
  justify-content: start;
  align-items: center;
}
.logo {
  display: block;
  width: 202px;
  cursor: pointer;
  margin-top: 5px;
}
.tel {
width: 20px;
display: block;
}
.nav_name {
  font-size: 20px;
  line-height: 40px;
}
span {
  display: block;
}
</style>